/* global window */
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Wysiwyg } from "../atoms";
import { StyledShopButton } from "../components/buttonShop";

import { getSiteMetaData, colors, sizes, pushTeliumView } from "../utils";
import { lessonTitleText } from "../utils/textStyles";
import { faqPageFieldsShape, localeShape } from "../utils/shapes";
import { GlobalContext } from "../context/globalContext";

const StyledFAQ = styled.div`
  .header {
    padding: 4rem 1rem 2rem;
    background: ${colors.gray100};

    @media (min-width: ${sizes.tablet}) {
      padding: 8rem 5% 4rem;
    }

    @media (min-width: ${sizes.desktop}) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12.5rem 10% 4rem 14%;
    }

    @media (min-width: ${sizes.xldesktop}) {
      padding: 14rem 19.5% 6rem 27%;
    }
  }

  .heading {
    @media (min-width: ${sizes.desktop}) {
      width: 45%;
    }

    @media (min-width: ${sizes.xldesktop}) {
      width: 50%;
    }
  }

  h1 {
    ${lessonTitleText}
    margin-bottom: 1rem;
  }

  .content {
    max-width: 37rem;

    @media (min-width: ${sizes.desktop}) {
      max-width: unset;
      width: 54%;
      padding-left: 0.625rem;
    }

    @media (min-width: ${sizes.xldesktop}) {
      width: 45%;
    }
  }

  .cta {
    display: flex;
    justify-content: center;
    padding: 3rem 1rem;
    background: ${colors.offwhite};

    @media (min-width: ${sizes.tablet}) {
      padding: 3.75rem 0;
    }

    @media (min-width: ${sizes.desktop}) {
      padding: 4rem 0;
    }

    @media (min-width: ${sizes.xldesktop}) {
      padding: 5rem 0;
    }
  }
`;

const FAQPage = ({ pageContext: { locale, page } }) => {
  const {
    heading,
    copy,
    faqButtonLabelTommy,
    faqButtonUrlTommy,
    faqButtonLabelCK,
    faqButtonUrlCK
  } = page;

  const { state } = useContext(GlobalContext);
  const { theme } = getSiteMetaData();
  const buttonLabel = theme === "TH" ? faqButtonLabelTommy : faqButtonLabelCK;
  const buttonUrl = theme === "TH" ? faqButtonUrlTommy : faqButtonUrlCK;

  const pageData = {
    page_title: heading,
    page_type: "faq",
    page_locale: locale.code
  };

  const handleFAQClick = () => {
    if (window.utag) {
      window.utag.link({
        tealium_event: "faq_button_click",
        button_href: buttonUrl,
        ...pageData
      });
    }
  };

  useEffect(() => {
    pushTeliumView({
      tealium_event: "page_view",
      ...pageData
    });

    state.updateState({ page: { ...pageData } });
  }, []);

  return (
    <StyledFAQ>
      <div className="header">
        <div className="bg" />
        <div className="heading">
          <h1>{heading}</h1>
        </div>
        <div className="content">
          <Wysiwyg content={copy} />
        </div>
      </div>
      <div className="cta">
        <StyledShopButton
          as="a"
          href={buttonUrl}
          onClick={handleFAQClick}
          type="default"
        >
          {buttonLabel}
        </StyledShopButton>
      </div>
    </StyledFAQ>
  );
};

FAQPage.propTypes = {
  pageContext: PropTypes.shape({
    page: faqPageFieldsShape,
    locale: localeShape.isRequired
  }).isRequired
};

export default FAQPage;
